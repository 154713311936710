import {Container, Row, Col} from 'react-bootstrap'
import {Paper} from '@mui/material'
import {Button} from '../../atoms/Button.js'

import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

export const Demo = () => {
  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  return (
    <InfoPageTemplate>
      <Container
        id='schedule'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <Paper
            elevation={4}
            style={{padding: '1rem'}}
            className='background--p-dark d-flex justify-content-center align-items-center'
          >
            <Row className='d-flex justify-content-center'>
              <Col
                xs={12}
                sm={8}
                lg={6}
              >
                <Paper
                  className='background--s-dark'
                  style={{padding: '3rem'}}
                  square={false}
                >
                  <h3
                    className='d-text__font--heading color--p-light'
                    style={{textWrap: 'balance'}}
                  >
                    See Pitchr In Action
                  </h3>
                  <h5
                    className='d-text__font--subheading color--s-light'
                    style={{textWrap: 'balance'}}
                  >
                    Schedule a demo first, to ensure you get the most out of our
                    platform
                  </h5>
                  <p
                    className='d-text__font--body color--p-light d-flex align-items-center'
                    style={{textWrap: 'balance', paddingTop: '1rem'}}
                  >
                    <span
                      className='material-symbols-outlined'
                      style={{marginRight: '0.5rem', color: 'greenyellow'}}
                    >
                      {'check'}
                    </span>
                    Find ready to buy prospects in new or existing markets
                  </p>
                  <p
                    className='d-text__font--body color--p-light d-flex align-items-center'
                    style={{textWrap: 'balance'}}
                  >
                    <span
                      className='material-symbols-outlined'
                      style={{marginRight: '0.5rem', color: 'greenyellow'}}
                    >
                      {'check'}
                    </span>
                    Access key stakeholders email-addresses
                  </p>
                  <p
                    className='d-text__font--body color--p-light d-flex align-items-center'
                    style={{textWrap: 'balance'}}
                  >
                    <span
                      className='material-symbols-outlined'
                      style={{marginRight: '0.5rem', color: 'greenyellow'}}
                    >
                      {'check'}
                    </span>
                    Get more time back on traditional prospecting and email writing
                  </p>
                </Paper>
              </Col>

              <Col
                style={{padding: '3rem'}}
                className='d-flex flex-column align-items-center justify-content-center'
              >
                <h3 className='d-text__font--subheading d-text__placement--center color--p-light'>
                  Ready To Scale Your Outreach?
                </h3>
                <Button
                  style={{marginTop: '1rem'}}
                  children={
                    <>
                      {'Schedule Demo'}
                      <span
                        className='material-symbols-outlined'
                        style={{marginLeft: '0.5rem'}}
                      >
                        {'calendar_month'}
                      </span>
                    </>
                  }
                  onClick={() =>
                    window.open(
                      'https://calendly.com/charlie-salesautomation/30min',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                  variant='outline-secondary'
                />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
