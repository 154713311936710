import {useMemo, useState} from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'

export const TreeDropdown = ({options, selected, setSelected}) => {
  const [expandedNodes, setExpandedNodes] = useState([])

  const setCheckedStatus = (nodes) => {
    return nodes.map((node) => {
      const isChecked = selected.includes(node.value)
      const isExpanded = expandedNodes.includes(node.value)
      const newNode = {
        ...node,
        checked: isChecked,
        expanded: isExpanded
      }
      if (node.children) {
        newNode.children = setCheckedStatus(node.children)
      }
      return newNode
    })
  }

  const updatedOptions = useMemo(
    () => setCheckedStatus(options),
    [options, selected, expandedNodes]
  )

  const findNodeByValue = (nodes, value) => {
    for (const node of nodes) {
      if (node.value === value) {
        return node
      }
      if (node.children) {
        const found = findNodeByValue(node.children, value)
        if (found) {
          return found
        }
      }
    }
    return null
  }

  const collectValues = (node) => {
    let values = [node.value]
    if (node.children) {
      for (const child of node.children) {
        values = values.concat(collectValues(child))
      }
    }
    return values
  }

  const handleNodeToggle = (currentNode) => {
    const {value, expanded} = currentNode
    const nodeInOptions = findNodeByValue(options, value)
    if (expanded) {
      setExpandedNodes((prevExpandedNodes) => [...prevExpandedNodes, value])
    } else {
      const valuesToCollapse = collectValues(nodeInOptions)
      setExpandedNodes((prevExpandedNodes) =>
        prevExpandedNodes.filter((v) => !valuesToCollapse.includes(v))
      )
    }
  }

  const handleChange = (currentNode) => {
    const {checked, value} = currentNode
    const nodeInOptions = findNodeByValue(options, value)
    if (nodeInOptions) {
      const valuesToProcess = collectValues(nodeInOptions)
      if (checked) {
        const newSelected = Array.from(new Set([...selected, ...valuesToProcess]))
        setSelected(newSelected)
      } else {
        const newSelected = selected.filter((val) => !valuesToProcess.includes(val))
        setSelected(newSelected)
      }
    }
  }

  return (
    <DropdownTreeSelect.default
      data={updatedOptions}
      onChange={handleChange}
      onNodeToggle={handleNodeToggle}
      showDropdown='always'
      showPartiallySelected
    />
  )
}
